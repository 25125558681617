import { useAppStore } from '#/stores/appStore'

export default defineNuxtRouteMiddleware(async (to) => {
  const token = to.params.token
  const query = to.query.confirmEmail
  const apiUri = import.meta.env.VITE_API_URL
  const { updateState } = useAppStore()
  try {
    if (token && query) {
      const res = await $fetch<{ success: boolean }>(`${apiUri}/confirmEmail`, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (res.success) {
        updateState('messages.showConfirmedSignup', true)
      }
    }
    return navigateTo(
      { name: 'auth-index-login', hash: '#confirmed' },
      { replace: true },
    )
  }
  catch (e) {
    console.error(e)
    return navigateTo({ name: 'auth-index-login' }, { replace: true })
  }
})
